import * as React from 'react';
import { observer } from 'mobx-react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableHeadRow, TableRow } from '../table/simple';
import { DishOptionSetList } from '../elements/dish.option-set-list';
import { DishIngredientsList } from '../elements/dish.ingredients-list';
import { DishComboChoiceList } from '../elements/dish.combo-list';
import Big from 'big.js';
import { SortType } from '@lib/common';

interface Props extends WithTranslation {
  items: T.Schema.Order.OrderDish;
  itemContent?: (dish: T.Schema.Order.OrderDish, index: number) => React.ReactNode;
  renderDetails?: T.Schema.Restaurant.Printer.RestaurantPrinter['receipt']['dishes'];
  isPrinting?: boolean;
}
interface Injected extends Props {}
interface State {}

@observer
class OrderBumpItemsClass extends React.Component<Props, State> {
  render() {
    const { t } = this.props as Injected;
    const { items, itemContent, isPrinting } = this.props;
    const details: T.Schema.Restaurant.Printer.RestaurantPrinter['receipt']['dishes'] = this.props.renderDetails || {
      enabled: true,
      prices: true,
      option_prices: true,
      combo_choice_names: true,
      sort_type: SortType.ByCart.toString(),
    };
    if (!details.enabled) {
      return null;
    }
    return (
      <>
        <div className="text-left">
          {items.subtitle && <p className="small lh14">{items.subtitle}</p>}
          {items.type !== 'combo' && (
            <div>
              {(items.option_sets || []).length > 0 && (
                <div className="m-t-1">
                  <DishOptionSetList 
                    option_sets={items.option_sets}
                    renderDetails={details}
                    isPrinting={isPrinting}
                    isBumpScreen={true}
                    dishQty={items.qty}/>
                </div>
              )}
              {(items.ingredients || []).length > 0 && (
                <div className="m-t-1">
                  <DishIngredientsList ingredients={items.ingredients} />
                </div>
              )}
            </div>
          )}

          {items.type === 'combo' && (
            <div className="m-t-1">
              <DishComboChoiceList 
                choices={items.choices}
                renderDetails={details}
                isBumpScreen={true}
                dishQty={items.qty} />
            </div>
          )}

          {items.notes && (
            <p className="m-t-1 small">
              {t('order.receipt.dishes.notes')}: {items.notes}
            </p>
          )}

          {/* {itemContent && itemContent(items, i)} */}
        </div>

        {details.prices && (
          <div>
            {typeof items.discount === 'number' && items.discount > 0 && (
              <>
                <p className="small">
                  <s>{t('currency', { value: items.price })}</s>
                </p>
                <p>{t('currency', { value: Big(items.price).minus(items.discount).toFixed(2) })}</p>
              </>
            )}
            {typeof items.discount === 'undefined' && (
              <p>{items.price !== 0 ? t('currency', { value: items.price }) : 'Free'}</p>
            )}
          </div>
        )}
      </>
    );
  }
}

export const OrderBumpItems = withTranslation()(OrderBumpItemsClass);
