import * as React from "react";
import { Translation } from "react-i18next";
import _capitalize from 'lodash/capitalize';
import { isNil } from "lodash";
import { RestaurantUtils } from "@lib/common";
import { ListWrapper, ListSection, List, ListTitle, ListItem, ListItemHighlight } from "../list/index";

interface Props {
	option_sets: T.Schema.Order.OrderDish["option_sets"];
	renderDetails: T.Schema.Restaurant.Printer.RestaurantPrinter["receipt"]["dishes"];
	isPrinting?: boolean;
	isBumpScreen?: boolean;
	dishQty?: number;
}

const RegularOptionSetRenderer = (props: {
	optionSet: T.Schema.Restaurant.Menu.RestaurantOptionSet;
	optionSets: T.Schema.Order.OrderDish["option_sets"];
	renderDetails: T.Schema.Restaurant.Printer.RestaurantPrinter["receipt"]["dishes"];
	isPrinting?: boolean;
	isBumpScreen?: boolean;
	dishQty?: number;
}) => {
	const { isPrinting, isBumpScreen, dishQty} = props;
	const optionSet = props.optionSet;
	const freeQty = optionSet.conditions.free_amount || 0;
	const options = optionSet.options.filter(option => option.quantity > 0);
	if (options.length === 0) {
		return null;
	}

	return (
		<Translation>
			{
				(t) => (
					<ListSection>
						{ (isPrinting && optionSet.print_hide_option_set_name) ||  (isBumpScreen && (optionSet.print_hide_option_set_name === undefined || optionSet.print_hide_option_set_name === true )) ? 
						null : <ListTitle>{optionSet.display_name || optionSet.name}</ListTitle> }
						<List>
							{options.map((option) => {
								if (props.renderDetails.option_prices) {
									const price = RestaurantUtils.dish.calculateOptionSetOptionPrice(option, optionSet, props.optionSets);

									if (option.quantity === 1) {
										return (
											<ListItem key={option._id} className="r-list-item">
												{((isPrinting && option.print_name) || (isBumpScreen && option.print_name)) ? option.print_name : option.name}{(price && !freeQty) ? <ListItemHighlight className="list-item-highlight">+{t("currency", { value: price })} x {dishQty || 1}</ListItemHighlight> : ""}
											</ListItem>
										);
									}

									return (
										<ListItem key={option._id} className="r-list-item">
											{((isPrinting && option.print_name) || (isBumpScreen && option.print_name)) ? option.print_name : option.name}{(price && !freeQty) ? (
												<ListItemHighlight className="list-item-highlight">+{t("currency", { value: price })} x {option.quantity}</ListItemHighlight>
											) : (
												<ListItemHighlight className="list-item-highlight"> x {option.quantity}</ListItemHighlight>
											)}
										</ListItem>
									);
								}

								if (option.quantity === 1) {
									return <ListItem key={option._id} className="r-list-item">{((isPrinting && option.print_name) || (isBumpScreen && option.print_name)) ? option.print_name : option.name}</ListItem>;
								}

								return (
									<ListItem key={option._id} className="r-list-item">
										{((isPrinting && option.print_name) || (isBumpScreen && option.print_name)) ? option.print_name : option.name} <ListItemHighlight className="list-item-highlight"> x {option.quantity}</ListItemHighlight>
									</ListItem>
								);
							})}
						</List>
					</ListSection>
				)
			}
		</Translation>
	);
}

const OptionSetWithPointsRenderer = (props: {
	optionSet: T.Schema.Restaurant.Menu.RestaurantOptionSet;
	optionSets: T.Schema.Order.OrderDish["option_sets"];
	renderDetails: T.Schema.Restaurant.Printer.RestaurantPrinter["receipt"]["dishes"];
	isPrinting?: boolean;
	isBumpScreen?: boolean;
	dishQty?: number;
}) => {
	const { isPrinting, isBumpScreen, dishQty } = props;
	const optionSet = props.optionSet;
	const options = RestaurantUtils.dish.groupOptionSetOptionByPosition(optionSet);
	if (Object.keys(options).length === 0) {
		return null;
	}

	return (
		<Translation>
			{
				(t) => (
					<>
						{Object.keys(options).map(key => {
							return (
								<ListSection key={key}>
									{ (isPrinting && optionSet.print_hide_option_set_name) || (isBumpScreen && (optionSet.print_hide_option_set_name === undefined || optionSet.print_hide_option_set_name === true )) ? 
										null : <ListTitle>{ (isNil(key) || key === 'undefined') ? '' :  _capitalize(key) + ':'} {optionSet.display_name || optionSet.name}</ListTitle>
									}
									<List>
										{
											options[key].map(option => {
												const quantityMultiplier = RestaurantUtils.dish.getOptionSetOptionDisplayingQuantityMultiplier(option)
												if (props.renderDetails.option_prices) {
													const price = RestaurantUtils.dish.calculateOptionSetOptionPrice(option, optionSet, props.optionSets);
													return (
														<ListItem key={option._id} className="r-list-item">
															{((isPrinting && option.print_name) || (isBumpScreen && option.print_name)) ? option.print_name : option.name}
															{quantityMultiplier && <ListItemHighlight className="list-item-highlight">{quantityMultiplier}</ListItemHighlight>}
															{(price) ? <ListItemHighlight className="list-item-highlight">+{t("currency", { value: price })} x {dishQty || 1}</ListItemHighlight> : ""}
														</ListItem>
													);
												}

												return (
													<ListItem key={option._id} className="r-list-item">
														{((isPrinting && option.print_name) || (isBumpScreen && option.print_name)) ? option.print_name : option.name}
														{quantityMultiplier && <ListItemHighlight className="list-item-highlight">{quantityMultiplier}</ListItemHighlight>}
													</ListItem>
												);
											})
										}
									</List>
								</ListSection>
							)
						})}
					</>
				)
			}
		</Translation>
	)
}

class DishOptionSetListClass extends React.Component<Props> {
	render() {
		const { option_sets, renderDetails, isPrinting, isBumpScreen, dishQty } = this.props;
		return (
			<ListWrapper>
				{option_sets.map((optionSet) => {
					if (optionSet.using_points) {
						return (
							<OptionSetWithPointsRenderer
								key={optionSet._id}
								optionSet={optionSet}
								optionSets={option_sets}
								renderDetails={renderDetails}
								isPrinting={isPrinting}
								isBumpScreen={isBumpScreen}
								dishQty={dishQty}
							/>
						);
					}

					return (
						<RegularOptionSetRenderer
							key={optionSet._id}
							optionSet={optionSet}
							optionSets={option_sets}
							renderDetails={renderDetails}
							isPrinting={isPrinting}
							isBumpScreen={isBumpScreen}
							dishQty={dishQty}
						/>
					)
				})}
			</ListWrapper>
		);
	}
}

export const DishOptionSetList = (DishOptionSetListClass);
