import React, { RefObject } from 'react';
import { MobxComponent } from "../../../mobx/components";
import { inject, observer } from "mobx-react";
import { HeaderContainer, HeaderMenuWrapper, LayoutContainer, MainContainer, MenuItem, SidebarContainer, SidebarLogoWrapper, SidebarMenuWrapper, HeaderMobile, HeaderMobileTitleWrapper, HeaderMobileLogoWrapper, HeaderMobileTitle, HeaderMobileMenuContainer, HeaderMobileMenuUpperWrapper, HeaderMobileMenuLowerWrapper, HeaderMobileMenuTitleWrapper, HeaderMobileMenuWrapper, HeaderTitleWrapper, MoreActionWrapper, HeaderMobileBackWrapper } from "./layout.c";
import { IconBell, IconBookings, IconCustomers, IconDashboard, IconDocument, IconLogout, IconMenus, IconOrders, IconPayment, IconSettings, IconStore, IconHamburger, IconClose, IconBack, IconMoreOptions, IconPlusRed, IconPlus, IconQuestionCircle, IconSettingsOutlined, IconGlobe, IconCW, IconTemplate, IconUploadFile } from './icons';
import { withTranslation, WithTranslation } from "react-i18next";
import { routes } from '../../../routes';
import { Button, CommonText, EllipsisIcon, RotateLoader, Sidebar } from '@lib/components';
import { CoreUtils } from '@lib/common';
import { NotificationMobileIcon, Notifications } from '../../ui/restaurant/notifications';
import { AudioUnlock } from '../AudioUnlock';
import styled from 'styled-components';
import { MoreActionSideBar } from './more-action-menu';
import { MoreActionTablet } from './more-action-menu-tablet';
import { isMobile } from 'react-device-detect';
import { BookingSettings } from '../../ui/restaurant/views/bookings/mobileBookings/modals/settings/bookingSettings';
const HeaderContainer_Height = "53px";
const PaginationWapper_Height = "72px";
import { CreateCustomerModal } from '../../ui/restaurant/views/customers/customers-v2/customerBody/createCustomerModal';
import { NotificationsList } from '../../ui/restaurant/notifications/list';

const LayoutDashBoardWrapper = styled.div<{ customStyle?: string, dashboard?: string, isDashboard?: boolean }>`
    height: 100%;
    background-color: ${({ customStyle }) => (customStyle === 'orders' ? '#fff' : '')};
    ${({ customStyle, dashboard, isDashboard }) => dashboard === 'v2.1' ?
        `margin-top: 0;
        @media(max-width: 768px) {
            ${(['orders', 'customers', 'settings'].includes(customStyle || '')) ? 'padding: 0;' : 
                (['bump', 'bookings'].includes(customStyle || '')) ? 'padding: 57px 0 0;' : 
                (isDashboard ? 'padding: 0;' :'padding: 1rem 1rem;')
            }
             ${(['list', 'bump'].includes(customStyle || '')) && 'padding: 0'}
        ${(['online_payments'].includes(customStyle || '')) && 'padding: 2rem;'}`
        : `${(['orders'].includes(customStyle || '')) ? 'padding: 0;' : `padding: 2rem;`}`
    }
    @media (max-width: 768px) {
        padding: ${({ customStyle, dashboard }) => (['bookings'].includes(customStyle || '') && dashboard === 'v2' && '73px 1rem 0')};
        
    }
   
`;

const HeaderRight = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
`;
const NotificationMobile = styled.div`
    display: none;
     @media (max-width: 768px) {
        display: flex;
    }
`

interface Props extends WithTranslation {
    children: React.ReactNode;
}
interface State {
    activeMobileMenu: boolean,
    activeSidebarMenu: boolean,
    isMobile: boolean,
    activeMoreActionMenu: boolean,
    createCustomerModal: boolean,
    toggleLocalize: boolean,
    lang?: string,
    showNotifications: boolean
}

@inject("store") @observer
class DashboardLayoutV2Class extends MobxComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            activeMobileMenu: false,
            activeSidebarMenu: false,
            isMobile: false,
            activeMoreActionMenu: false,
            createCustomerModal: false,
            toggleLocalize: false,
            lang: 'en',
            showNotifications: false
        };
        this.closeMoreActionMenuSideBar = this.closeMoreActionMenuSideBar.bind(this)
    }

    componentDidMount(): void {
        this.injected.store.service.restaurant.get();
        this.setState({
            isMobile: window.innerWidth < 768
        })
        window.addEventListener('resize', () => {
            if(window.innerWidth < 768) {
                this.setState({
                    isMobile: true
                })
            } else {
                this.setState({
                    isMobile: false
                })
            }
        })
    }

    componentWillUnmount(): void {
        const { store } = this.injected;
        store.setLocalizeRef(React.createRef());
    }

    toggleMobileMenu() {
        this.setState({
            activeMobileMenu: !this.state.activeMobileMenu,
        })
    }
    toggleCustomerMenu() {
        this.injected.store.updateCustomerControl({ openCreateCustomer: !this.injected.store.customerControl.openCreateCustomer })
    }

    toggleSidebarMenu() {
        this.setState({
            activeSidebarMenu: !this.state.activeSidebarMenu,
        })
    }
    openMoreActionMenuSideBar() {
        this.setState({
            activeMoreActionMenu: true,
        })
    }
    closeMoreActionMenuSideBar() {
        this.setState({
            activeMoreActionMenu: false,
        })
    }

    render() {
        const { store } = this.injected;
        const r = store.restaurant;
        const reseller = store.reseller;
        const { restrictions, organisation } = store;
        const { mobileSettingsOpen, setMobileSettingOpen } = store;
	    const notificationsEnabled = restrictions._.restaurantNotificationsEnabled;
		// Should move to reseller setting section later
		let documentationLink =  reseller?.admin.documentation_link;
        if (!r) {
            return (<div style={{ marginTop: '100px'}}><RotateLoader size={3} /></div>)
        }
        if (!reseller) {
            return (<div style={{ marginTop: '100px'}}><RotateLoader size={3} /></div>)
        }
        const logoTopNavs = r.website.sections.top_nav.images.logo;

        if (reseller._id === 'ceorestaurant') {
            documentationLink = 'https://support.ceorestaurant.my';
        }
        const { activeSidebarMenu, activeMobileMenu, isMobile, toggleLocalize, lang } = this.state;
        const shouldOnlinePaymentAllowed =
            !!r.settings.payments.stripe?.connect_account_id?.length &&
            restrictions._.onlinePaymentEnabled;
        const pathname = store.router.s.path;
        const pathNameLength = store.router.s.path.split("/").length - 1;
        const pathNameEnd = store.router.s.path.split("/")[pathNameLength];
        let links = [
            {
                id: "nav-link-home",
                tip: "Home",
                icon: <IconDashboard />,
                active: !!routes.restaurant_dashboard.pattern.match(pathname),
                onClick: () => {store.router.push(`/restaurant/${r._id}`); this.setState({showNotifications: false})},
                allowed: restrictions.restaurant.dashboard,
            },
            {
                id: "nav-link-orders",
                tip: "Orders",
                icon: <IconOrders />,
                active: !!routes.restaurant_orders.pattern.match(pathname)
                    || !!routes.restaurant_orders_list.pattern.match(pathname)
                    || !!routes.restaurant_orders_bump.pattern.match(pathname),
                onClick: () => {
                    if (restrictions.restaurant.orders_board) {
                        store.router.push(`/restaurant/${r._id}/orders`);
                    } else if (
                        !restrictions.restaurant.orders_board &&
                        (restrictions.restaurant.orders_bump || restrictions.restaurant.orders_list)
                    ) {
                        if (restrictions.restaurant.orders_bump) {
                            store.router.push(`/restaurant/${r._id}/orders/bump`);
                        } else if (restrictions.restaurant.orders_list) {
                            store.router.push(`/restaurant/${r._id}/orders/list`);
                        }
                    }
                    this.setState({showNotifications: false})
                },
                allowed: restrictions._.restaurantOrderViews.length > 0,
              },
			{
				id: "nav-link-bookings",
				tip: "Bookings",
				icon: <IconBookings />,
				active: !!routes.restaurant_bookings.pattern.match(pathname),
				onClick: () => store.router.push(`/restaurant/${r._id}/bookings`),
				allowed: restrictions.restaurant.bookings,
			},
			{
				id: "nav-link-customers",
				tip: "Customers",
				icon: <IconCustomers />,
				active: !!routes.restaurant_customers.pattern.match(pathname),
				onClick: () => store.router.push(`/restaurant/${r._id}/customers`),
				allowed: restrictions.restaurant.customers,
			},
			{
				id: "nav-link-menus",
				tip: "Menus",
				icon: <IconMenus />,
				active: !!routes.restaurant_menus.pattern.match(pathname),
				onClick: () => store.router.push(`/restaurant/${r._id}/menus`),
				allowed: restrictions.restaurant.menus,
			},
			{
				id: "nav-link-connect",
				tip: "Online Payments",
				icon: <IconPayment />,
				active: !!routes.restaurant_stripe_connect.pattern.match(pathname),
				onClick: () => { store.router.push(`/restaurant/${r._id}/online_payments`) },
				allowed: shouldOnlinePaymentAllowed,
			},
			{
				id: "nav-link-settings",
				tip: "Settings",
				icon: <IconSettings />,
				active: !!routes.restaurant_settings.pattern.match(pathname),
				onClick: () => store.router.push(`/restaurant/${r._id}/settings`),
				allowed: restrictions._.restaurantSettingsEnabled,
			},
			{
				id: "nav-link-view-store",
				tip: "View Store",
				icon: <IconStore />,
				active: false,
				onClick: () => {
					window.open(store.storeURL, "_blank");
				},
				allowed: restrictions._.restaurantSettingsEnabled || restrictions.restaurant.menus,
			},
			{
				id: "nav-link-documentation",
				tip: "Documentation",
				icon: <IconDocument />,
				active: false,
				onClick: () => {
					window.open(documentationLink, "_blank");
				},
				allowed: documentationLink,
			},
		];

        const openBookingSetting = () => {
            setMobileSettingOpen(true);
        };

        const closeBookingSetting = () => {
            setMobileSettingOpen(false);
        };

        return (
            <LayoutContainer>
                <SidebarContainer className={`${activeSidebarMenu ? 'active' : ''}`}>
                    <SidebarLogoWrapper className='sidebar-logo-wrapper'>
                        <HeaderTitleWrapper onClick={() => this.toggleSidebarMenu()}>
                            {logoTopNavs ?
                                <img
                                    src={CoreUtils.image.uc(
                                        logoTopNavs,
                                        {
                                            resize: "x150",
                                            format: "auto"
                                        })}
                                    style={{
                                        height: 40,
                                        width: activeSidebarMenu ? 40 : "100%",
                                        objectFit: "contain"
                                    }} />
                                : <span className="default-logo"><IconCW /></span>}

                            <span>{r.name || 'Acme Pizza'}</span>
                        </HeaderTitleWrapper>
                        <MenuItem onClick={() => this.toggleSidebarMenu()}><IconClose /></MenuItem>
                    </SidebarLogoWrapper>
                    <SidebarMenuWrapper className='sidebar-menu-wrapper'>
                        {links.map(link => !link.allowed ? null :
                            <MenuItem
                            key={link.id}
                             active={link.active}
                             onClick={link.onClick}
                             tip={activeSidebarMenu ? undefined : link.tip}>
                             {link.icon}
                             <span>{link.tip}</span>
                            </MenuItem>
                        )}
                    </SidebarMenuWrapper>
                </SidebarContainer>
                <MainContainer>
                    <HeaderContainer className={organisation && organisation.dashboard || ''}
                        style={{ paddingRight: organisation && organisation.dashboard === 'v2.1' ? 8 : 24 }}>
                        <HeaderMenuWrapper>
                            <MenuItem onClick={() => {
                                if (store.activeMobileMenu.referrer.length > 0) {
                                    store.setActiveMobileMenu(
                                        store.activeMobileMenu.referrer.pop()!,
                                        Array.from(new Set(store.activeMobileMenu.referrer))
                                    )
                                } else {
                                    store.router.push("/");
                                    store.notifications.ablyRestaurantStop();
                                }
                            }}>
                                <IconBack />
                            </MenuItem>
                            {organisation && organisation.dashboard === 'v2.1'
                                && <span> {r.name} </span> }
                        </HeaderMenuWrapper>

                        <HeaderMenuWrapper>
                            {organisation && organisation.dashboard === 'v2.1'
                                && <>
                                    {pathNameEnd === 'menus' &&
                                        <><MenuItem
                                            onClick={() => {
                                                store.setActiveMobileMenu('templates', 'menus')
                                            }}>
                                            <IconTemplate />
                                            <p style={{ fontFamily: 'poppins' }}>Template</p>
                                        </MenuItem>
                                            <MenuItem
                                                onClick={() => {
                                                    store.setActiveMobileMenu('menus-upload-service', 'menus')
                                                }}>
                                                <IconUploadFile />
                                                <p style={{ fontFamily: 'poppins' }}>Upload</p>
                                            </MenuItem> </>}
                                    <MenuItem
                                        style={{ position: 'relative' }}
                                        onClick={() => {
                                            if (!toggleLocalize) {
                                                store.localizeRef.current?.appendChild(store.localizeWidget!)
                                                //@ts-ignore
                                                Localize.showWidget()
                                            } else {
                                                //@ts-ignore
                                                Localize.hideWidget()
                                            }
                                            this.setState({
                                                toggleLocalize: !toggleLocalize
                                            })
                                        }}>
                                        <IconGlobe /> <span className="lang-text" style={{ textTransform: 'uppercase' }}>{store.cwLang || 'en'}</span>
                                        <div ref={store.localizeRef} style={{
                                            position: 'absolute',
                                            height: 200,
                                            top: 0,
                                            left: 130
                                        }}></div>
                                    </MenuItem>
                                </>
                            }
                            {!notificationsEnabled && 
                                <MenuItem>
                                    <AudioUnlock className="m-r-5" />
                                </MenuItem>}
                            <MenuItem style={{ paddingBottom: 4 }}>

                                {notificationsEnabled && !isMobile &&  <Notifications />}

                            </MenuItem>
                            <MenuItem onClick={() => store.service.logout()}><IconLogout /></MenuItem>
                        </HeaderMenuWrapper>
                    </HeaderContainer>
                    <HeaderMobile isMobile={isMobile} customStyle={pathNameEnd}>
                        {store.activeMobileMenu.referrer.length > 0 ?
                            <MenuItem onClick={() => {
                                if (store.activeMobileMenu.name === 'edit-custom-checkout-field') {
                                    let service = store.activeMobileModal.split('|')[0]
                                    if (service.includes('delivery')) {
                                        service = 'deliveries';
                                    } else if (service.includes('dine_in')) {
                                        service = 'dine-ins';
                                    } else if (service.includes('table_booking')) {
                                        service = 'table-booking';
                                    }
                                    store.setActiveMobileMenu(
                                        store.activeMobileMenu.referrer.pop()!,
                                        Array.from(new Set(store.activeMobileMenu.referrer.concat(service)))

                                    );
                                    store.setActiveMobileModal('');
                                } else {
                                    store.setActiveMobileMenu(
                                        store.activeMobileMenu.referrer.pop()!,
                                        Array.from(new Set(store.activeMobileMenu.referrer))
                                    )
                                }
                            }}>
                                <IconBack />
                            </MenuItem>
                            : <MenuItem
                                onClick={() => this.toggleMobileMenu()}>
                                <IconHamburger />
                            </MenuItem>}
                        <HeaderMobileTitleWrapper isDevelopers>
                            {logoTopNavs && <HeaderMobileLogoWrapper>
                                <img src={CoreUtils.image.uc(logoTopNavs, { resize: "x150", format: "auto" })} style={{ maxWidth: "32px", height: 'auto', width: '100%', objectFit: 'contain' }} />
                            </HeaderMobileLogoWrapper>}
                            <HeaderMobileTitle>
                                {store.activeMobileMenu.referrer.length > 0 ?
                                    store.activeMobileMenu.name.replace(/-/g, ' ')
                                    : links.map(link => !link.active ? null :
                                        <span key={link.id}>{link.tip}</span>
                                    )}
                            </HeaderMobileTitle>
                        </HeaderMobileTitleWrapper>

                        {notificationsEnabled && !(['bookings', 'settings', 'customers'].includes(pathNameEnd || '')) &&  <AudioUnlock className="m-r-5" /> }
                        {!(['bookings', 'settings', 'customers'].includes(pathNameEnd || '')) && <MenuItem style={{paddingBottom: 4}}>
                            {notificationsEnabled && <Notifications modal />}
                        </MenuItem>}
                        <MenuItem>
                            {pathNameEnd === 'menus' && (
                                <span onClick={() => store.setActiveMobileModal('menus')}>
                                    <IconMoreOptions />
                                </span>
                            )}
                            {store.activeMobileMenu.name == 'edit-promo' &&
                                <span onClick={() => store.setActiveMobileModal('edit-promo')}>
                                    <IconMoreOptions />
                                </span>
                            }
                            {store.activeMobileMenu.name == 'edit-webhooks' &&
                                <span onClick={() => store.setActiveMobileModal('edit-webhooks')}>
                                    <IconMoreOptions />
                                </span>
                            }
                            {store.activeMobileMenu.name == 'edit-conditional-fees' &&
                                <span onClick={() => store.setActiveMobileModal('edit-conditional-fees')}>
                                    <IconMoreOptions />
                                </span>
                            }
                            {store.activeMobileMenu.name == 'printer-setup' &&
                                <span style={{ height: 24 }} onClick={() => store.setActiveMobileMenu('add-printer', 'printer-setup')}>
                                    <IconPlusRed width={24} height={24} />
                                </span>
                            }
                            {store.activeMobileMenu.name == 'edit-printer' &&
                                <span onClick={() => store.setActiveMobileModal('edit-printer')}>
                                    <IconMoreOptions />
                                </span>
                            }
                            {store.activeMobileMenu.name == 'webhooks' &&
                                <span style={{ height: 24 }} onClick={() => store.setActiveMobileMenu('create-webhooks', 'webhooks')}>
                                    <IconPlusRed width={24} height={24} />
                                </span>
                            }
                            {
                                pathNameEnd === 'bookings' && organisation && organisation.dashboard === 'v2.1' && (
                                    <IconSettingsOutlined onClick={openBookingSetting} />
                                )
                            }
                            {
                                pathNameEnd === 'customers' && organisation && organisation.dashboard === 'v2.1' && (
                                    <>
                                        <Button size='xxs' display='flex' type="button" className="no-round" paddinglr={5} color="transparent" onClick={() => { this.toggleCustomerMenu() }}>
                                            <IconPlus />
                                        </Button>
                                        <a target="_blank" href="https://support.cloudwaitress.com/platform-information/customer-accounts" className="">
                                            <Button size='xxs' display='flex' type="button" className="no-round" paddinglr={5} color="transparent" >
                                                <IconQuestionCircle />
                                            </Button>
                                        </a>
                                    </>
                                )
                            }

                        </MenuItem>

                        <Sidebar
                            isOpen={this.state.activeMoreActionMenu}
                            headerTitle={"More Action"}
                            onClose={() => this.closeMoreActionMenuSideBar()}
                            width={window.innerWidth}>
                            <MoreActionSideBar onClose={this.closeMoreActionMenuSideBar} />
                        </Sidebar>
                        <Sidebar
                            isOpen={store.customerControl.openCreateCustomer}
                            headerTitle={"Customer - Create"}
                            onClose={() => this.toggleCustomerMenu()}
                            width={window.innerWidth}
                            version='v2'
                        >
                            <CreateCustomerModal />
                        </Sidebar>

                        <BookingSettings isOpen={mobileSettingsOpen} handleClose={closeBookingSetting} />
                        {organisation && organisation.dashboard === 'v2' && <HeaderMenuWrapper>
                            <MenuItem>
                                {notificationsEnabled && isMobile &&  <Notifications />}
                            </MenuItem>
                        </HeaderMenuWrapper>}
                    </HeaderMobile>
                    <HeaderMobileMenuContainer className={`${activeMobileMenu ? 'active' : ''}`}>
                        <HeaderMobileMenuUpperWrapper>
                            <HeaderMobileMenuTitleWrapper>
                                <HeaderMobileTitleWrapper className='HeaderMobileTitleWrapper'>
                                    {logoTopNavs && <HeaderMobileLogoWrapper>
                                        <img src={CoreUtils.image.uc(logoTopNavs, { resize: "x150", format: "auto" })} style={{ maxWidth: "32px", height: 'auto', width: '100%', objectFit: 'contain' }} />
                                    </HeaderMobileLogoWrapper>}
                                    <HeaderMobileTitle>
                                        {r.name || 'Acme Pizza'}
                                    </HeaderMobileTitle>
                                </HeaderMobileTitleWrapper>

                                <MenuItem onClick={() => this.toggleMobileMenu()}>
                                    <IconClose />
                                </MenuItem>
                            </HeaderMobileMenuTitleWrapper>
                            <HeaderMobileBackWrapper>
                                <MenuItem onClick={() => {
                                    store.router.push("/");
                                    store.notifications.ablyRestaurantStop();
                                    this.setState({showNotifications: false})
                                }}>
                                    <IconBack />
                                    <CommonText size='small' color='#4B4F5E' weight={400} style={{ fontFamily: 'Lato' }}>
                                        Back to Restaurants
                                    </CommonText>
                                </MenuItem>
                            </HeaderMobileBackWrapper>
                            <HeaderMobileMenuWrapper>
                                {links.map(link => !link.allowed ? null :
                                    <MenuItem
                                        key={link.id}
                                        active={link.active}
                                        onClick={() => {
                                            this.setState({
                                                activeMobileMenu: false, showNotifications: false
                                            }, () => {
                                                store.setActiveMobileMenu('settings')
                                                link.onClick()
                                            })

                                        }}
                                        tip={activeMobileMenu ? undefined : link.tip}>
                                        {link.icon}
                                        <p>{link.tip}</p>
                                    </MenuItem>
                                )
                                }
                            </HeaderMobileMenuWrapper>
                            <HeaderMobileMenuWrapper>
                                <MenuItem active={this.state.showNotifications} style={{ paddingLeft: 12 }} onClick={() => this.setState({ showNotifications: true, activeMobileMenu: false})}>
                                    {notificationsEnabled && <NotificationMobileIcon />}
                                    <p>Notifications</p>
                                </MenuItem>
                                <MenuItem onClick={() => store.service.logout()}>
                                    <IconLogout />
                                    <p>Logout</p>
                                </MenuItem>
                            </HeaderMobileMenuWrapper>
                        </HeaderMobileMenuUpperWrapper>
                    </HeaderMobileMenuContainer>
                    <LayoutDashBoardWrapper
                        className={organisation && organisation.dashboard || ''}
                        customStyle={pathNameEnd}
                        dashboard={organisation && organisation.dashboard || ''}
                        isDashboard={pathNameEnd == r._id}>
                        {this.props.children}
                    </LayoutDashBoardWrapper>
                </MainContainer >
                <NotificationMobile>
                <Sidebar isOpen={this.state.showNotifications} headerTitle={''} onClose={() => this.setState({ showNotifications: false, activeMobileMenu: true})}>
                    <NotificationsList />
                </Sidebar>
                </NotificationMobile>
            </LayoutContainer >
        )
    }
}

export const DashboardLayoutV2 = withTranslation()(DashboardLayoutV2Class);
